<template>
  <div>
    <div class="pt-16 md:pt-20 px-6 text-gray-200 pb-4 md:pb-8">
      <div class="max-w-5xl mx-auto">
        <div class="flex flex-col md:flex-row justify-start md:justify-center items-start md:items-center pb-6 md:pb-8">
          <!--<h1 class="text-white md:text-center text-5xl lg:text-6xl font-bold leading-none sm:leading-tighter">The easiest way to run your livestock show.</h1>-->
          <h1 class="text-white md:text-center text-5xl lg:text-6xl font-bold leading-none sm:leading-tighter">
            A team
            <span class="inline-flex relative text-white">
              <span aria-hidden="" class="absolute inset-0 bg-showman" style="transform: skewY(-1.3deg) scaleX(1.05) scaleY(1.05);"></span>
              <span class="relative">dedicated</span>
            </span>
            to serving the livestock industry.</h1>
        </div>

        <div class="max-w-3xl mx-auto text-xl sm:text-2xl text-gray-200 md:text-center leading-snug sm:tracking-snug pb-10">
          <p class="font-light mb-6">
            We are a scrappy bootstrapped team committed to great customer service and building intuitive tools. Our mission is to make running livestock shows easier and more accessible, to help promote and grow the livestock industry.
          </p>
        </div>
      </div>
    </div>
    <!--<div class="bg-white border-t-8 border-b-8 border-showman">
      <div class="px-4 py-12 sm:px-6 sm:py-16 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
          <h2 class="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">🔥 Showman is hiring! 🔥</h2>
          <p class="mx-auto mt-4 max-w-4xl  sm:text-lg leading-6 text-gray-800">Showman's mission is to make running livestock shows easier and more accessible, to help promote and grow the livestock industry. <span class="font-semibold">We are looking for energetic go getters, leaders, self-starters to join our Showman team. We have part time positions and full time positions potentially available. </span></p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="https://jobs.gusto.com/boards/showman-61028a22-fcff-4867-af5a-ae6e5fab9dbd" target="_blank" class="rounded-md bg-showman px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Apply Now</a>
          </div>
        </div>
      </div>
    </div>-->
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="bg-gray-900 pb-12 sm:pb-20">
      <div class="lg:mx-auto lg:max-w-6xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div class="py-8 lg:py-0">
          <div class="mx-auto max-w-md px-4 sm:px-6 lg:px-0 lg:py-10 ">
            <div class="bg-white rounded-lg">
              <div class="">
                <div class="aspect-w-2 aspect-h-2">
                  <img class="object-cover rounded-t-lg" src="https://res.cloudinary.com/showman/image/upload/w_510/v1715392826/IMG_0651_copy.jpg" alt="">
                </div>
                <div class="p-4">
                  <div class="text-lg leading-6 font-medium space-y-1">
                    <h3>Josh Mayne</h3>
                    <p class="text-gray-700">Founder / Product</p>
                  </div>
                  <div class="text-lg">
                    <p class="text-gray-700">
                      Showman strives to provide a show management platform that is simplistic and easy to use for exhibitors and show management. With each new show brought on board, I learn more ways to improve the application so that your experience continues to get better and better. My highest priority is you, the customer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          <!-- Content area -->
          <div class="pt-12 sm:pt-16 lg:pt-10">
            <h2 class="text-3xl text-white font-extrabold tracking-tight sm:text-4xl">
              On a mission to empower livestock shows
            </h2>
            <div class="mt-6 text-gray-200 space-y-6">

              <!--<p class="text-white font-extrabold text-xl tracking-tight sm:text-2xl">
                Born and Bred
              </p>-->
              <p class="text-base leading-7">
                <span class="tracking-tight font-bold">Born & Bred </span> - You could say livestock is in my genes; as the son of two Agricultural Teachers & FFA advisors, raising livestock was just a part of life. I got my start raising pigs but eventually made the switch to Boer goats. After graduating high school, I pursued a degree in computer science. Showman is a natural alignment of my two passions- the livestock industry and application development.
              </p>
              <!--<p class="text-white font-extrabold text-xl tracking-tight sm:text-2xl">
                The Launch
              </p>-->
             <p class="text-base leading-7">
                <span class="tracking-tight font-bold">The Launch</span> - In 2016, I left my 9 to 5 programming job with the dream of building something myself. The chance to be innovative and contribute to the livestock industry was well worth the risk. After trying a few different ventures, I wrote the first lines of code for Showman in the summer of 2018 at my parents’ kitchen table. As a solo founder, there were quite a few late nights coding and early mornings taking support calls, but the thrill of putting a helpful tool into people’s hands kept me excited and motivated each day. In 2019, Showman successfully launched an early access with 17 shows. In 2020, that grew to 100 shows and in 2021, 350 shows. Thanks for being along for the journey!
              </p>
            </div>
          </div>

          <!-- Stats section -->
          <div class="mt-10">
            <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
              <div class="border-t-2 border-gray-100 pt-6">
                <dt class="text-base font-medium text-gray-200">Founded</dt>
                <dd class="text-3xl font-extrabold tracking-tight text-gray-100">2019</dd>
              </div>

              <div class="border-t-2 border-gray-100 pt-6">
                <dt class="text-base font-medium text-gray-200">Shows</dt>
                <dd class="text-3xl font-extrabold tracking-tight text-gray-100">2000+</dd>
              </div>

              <div class="border-t-2 border-gray-100 pt-6">
                <dt class="text-base font-medium text-gray-200">Exhibitors</dt>
                <dd class="text-3xl font-extrabold tracking-tight text-gray-100">145K+</dd>
              </div>

              <div class="border-t-2 border-gray-100 pt-6">
                <dt class="text-base font-medium text-gray-200">Entries</dt>
                <dd class="text-3xl font-extrabold tracking-tight text-gray-100">985K+</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-900 text-gray-100">
      <div class="mx-auto py-12 px-4 max-w-5xl sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12">
          <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet the team</h2>
          <ul role="list" class="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
            <li>
              <div class="flex">
                <div class="mr-4">
                  <img class="object-cover shadow-lg rounded-lg" src="https://res.cloudinary.com/showman/image/upload/c_scale,w_160/v1633113667/Susan_Headshot" alt="">
                </div>
                <div class="w-3/4">
                  <div class="text-lg leading-6 font-medium space-y-1 mb-4">
                    <h3>Susan Burner</h3>
                    <p class="text-showman">Show Specialist</p>
                  </div>
                  <div class="text-lg">
                    <p class="text-gray-400">Susan has been hosting livestock shows for the past {{new Date().getFullYear() - 2007}} years. She has a passion for working with youth and promoting the livestock industry.</p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="mr-4">
                  <img class="object-cover shadow-lg rounded-lg" src="https://res.cloudinary.com/showman/image/upload/c_scale,w_160/v1633314614/Olivia_Headshot.png" alt="">
                </div>
                <div class="w-3/4">
                  <div class="space-y-4">
                    <div class="text-lg leading-6 font-medium space-y-1">
                      <h3>Olivia McHale</h3>
                      <p class="text-showman">Show Specialist</p>
                    </div>
                    <div class="text-lg">
                      <p class="text-gray-400">Olivia is a graduate of WVU in Animal Science with an MBA in Finance from USC. Her background in the show industry runs deep from cattle, sheep and goats. She enjoys assisting a varying level of shows by playing a small role in making their show a success.</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="mr-4">
                  <img class="object-cover shadow-lg rounded-lg" src="https://res.cloudinary.com/showman/image/upload/c_scale,w_160/v1715380114/Layne_headshot.jpg" alt="">
                </div>
                <div class="w-3/4">
                  <div class="space-y-4">
                    <div class="text-lg leading-6 font-medium space-y-1">
                      <h3>Layne Lassnick</h3>
                      <p class="text-showman">Show Specialist</p>
                    </div>
                    <div class="text-lg">
                      <p class="text-gray-400">Layne currently resides in Northeast, Ohio and has a show industry background in multiple species, which allows her to fully support our show hosts.</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="mr-4">
                  <img class="object-cover shadow-lg rounded-lg" src="https://res.cloudinary.com/showman/image/upload/c_scale,w_160/v1715392479/Brandy.jpg" alt="">
                </div>
                <div class="w-3/4">
                  <div class="space-y-4">
                    <div class="text-lg leading-6 font-medium space-y-1">
                      <h3>Brandy Albert</h3>
                      <p class="text-showman">Show Specialist</p>
                    </div>
                    <div class="text-lg">
                      <p class="text-gray-400">Brandy is a graduate of the University of Florida. She has over 17 years of online registration experience in the sports and livestock industries. She spends most of her spare time at livestock shows with her family.</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- More people... -->
          </ul>
        </div>
      </div>
    </div>

    <!--<div class="bg-brand-blue-darkest">
      <div class="max-w-6xl mx-auto px-8 py-12 font-sans">
        <a name="faq"></a>
        <h2 class="sm:text-4xl text-3xl mb-8 text-white">Let's Get Personal</h2>

        <div class="block md:flex border-t-2 border-gray-600 leading-normal py-6">
          <h3 class="text-base md:text-lg text-white font-bold w:full md:w-1/3 pr-0 md:pr-3 mb-4 m:mb-0">
            Is this being made by a big company?
          </h3>
          <div class="w:full md:w-2/3 pl-0 md:pl-3">
            <p class="text-base md:text-lg text-brand-blue-lighter ">
              No it's just me, Josh Mayne. A Boer goat breeder and computer programmer interested in combining my two passions.
            </p>
            <img class="mt-2 rounded" src="https://res.cloudinary.com/showman/image/upload/e_art:athena,h_350/v1606589124/Josh_Profile.jpg"  alt="A photo of Josh looking friendly and trustworthy">
          </div>
        </div>
        <div class="block md:flex border-t-2 border-gray-600 leading-normal py-6">
          <h3 class="text-base md:text-lg text-white font-bold w:full md:w-1/3 pr-0 md:pr-3 mb-4 m:mb-0">
            How long have you been involved in the Boer goat industry?
          </h3>
          <p class="text-base md:text-lg text-brand-blue-lighter w:full md:w-2/3 pl-0 md:pl-3">
            As the son of two Agricultural Teachers & FFA advisors, I grew up around agriculture.
            My adventure in livestock began when I was 8 years old. I got started through 4-H, raising pigs as a market and breeding project.
            <br>2008 was my first year raising goats, I bought two wethers as a 4-H market project.
            My involvement in Boer goats has grown since then, I now help manage <a class="text-brand-blue-light" href="http://maynesboergoats.com">Mayne Boer Goats</a> in Union Bridge, MD.
            Currently our herd has about 35 breeding age does.
          </p>
        </div>
        <div class="block md:flex border-t-2 border-gray-600 leading-normal py-6">
          <h3 class="text-base md:text-lg text-white font-bold w:full md:w-1/3 pr-0 md:pr-3 mb-4 m:mb-0">
            Do you know what you are doing?<br>Building web apps seems complicated.
          </h3>
          <p class="text-base md:text-lg text-brand-blue-lighter w:full md:w-2/3 pl-0 md:pl-3">
            I graduated from UMBC in 2013 with a degree in Computer Science and a minor in Mathematics.
            I spent the next 4 years developing web apps and doing design automation for Bechtel, an engineering and construction company.
            Since February 2017 I have been working on side projects to learn new technologies and hone my skills for the moment I would be able to launch a product like Showman.
            <br><br>
            Here is <a class="text-brand-blue-light" href="https://joshmayne.com">my personal site</a> for more information about me.
          </p>
        </div>
      </div>
    </div>-->

  </div>
</template>

<script>
export default {
  data () {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    }
  }
}
</script>

<style>

</style>
